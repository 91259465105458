<template>
    <div id="app-filter-vendor">
        <div class="row grid-row ">
            <div class="col-md-4 mb-2 mb-md-0">
                <v-select :placeholder="translationStrings.labelCountry" v-model="filter.selectedCountry" :options="calculatedCountryOptions" />
            </div>
            <div class="col-md-2 mb-2 mb-md-0">
                <input type="text" :disabled="!filter.selectedCountry" class="form-control" v-model="filter.zipcode" :placeholder="translationStrings.Zipcode">
            </div>
            <div class="col-md-6 mb-2 mb-md-0">
                <v-select :placeholder="translationStrings.Continent" v-model="filter.selectedContinent" :options="calculatedContinentOptions" />
            </div>
        </div>
        <div class="row grid-row mt-md-3">
            <div class="col-md-6 mb-2 mb-md-0">
                <v-select multiple :placeholder="translationStrings.Ilookfor" v-model="filter.selectedTypes" :options="calculatedTypeOptions" />
            </div>
            <div class="col-md-6 mb-2 mb-md-0">
                <input type="text" class="form-control" v-model="filter.search" :placeholder="translationStrings.EnterName">
            </div>
        </div>

        <div class="row grid-row mt-md-3">
            <div class="col-md-6 mb-2 mb-md-0">

            </div>
            <div class="col-md-6 mb-2 mb-md-0">
                <div class="checkboxlist-container">
                    {{translationStrings.AuthorizedProcessors}}<br />
                    <label v-for="item in authorizedProcessorsOptions" :key="item.label"><input type="checkbox" v-model="filter.authorized" :value="item.label"/>&nbsp;{{item.label}}</label>
                </div>
            </div>
        </div>


        <div class="row grid-row mt-md-4">
            <div class="col-md-6 mb-1 mb-md-0">
                <button type="submit" class="btn btn-outline-light btn-block mb-2" v-on:click="resetForm">{{translationStrings.labelReset}}</button>
            </div>
            <div class="col-md-6 mb-1 mb-md-0">
                <a class="btn-outline-secondary btn link-animated btn-block" v-on:click="sendForm" target="_self">{{translationStrings.labelShowResults}}<span class="icon hawafonticon hawa-arrow-right icon-animated--right icon-animated icon--right"></span></a>
            </div>
        </div>
    </div>
</template>
<script>

     import _debounce from 'lodash/debounce'

    // https://vue-select.org/api/props.html#options
    export default {
        name: 'app',
        components: {
        },
        props: {
            filterData: {
                type: Array,
                default: () => [
                    {
                        continent: '',
                        country: '',
                        processor: 1,
                        sourcetype: {}
                    }
                ]
            },
            whitelist: {
                type: Array,
                default: () => []
            },
            translationStrings: {
                type: Object,
                default: () => {}
            }
        },
        data: function () {
            return {
                initialized: false,
                continents: [],
                countries: [],
                appearance: [],
                calculatedCountryOptions: [],
                filter: {
                    selectedContinent: '',
                    selectedCountry: '',
                    search: '',
                    zipcode: '',
                    selectedTypes: [],
                    authorized: []
                },
                defaultFilter: {
                    selectedContinent: '',
                    selectedCountry: '',
                    search: '',
                    zipcode: '',
                    selectedTypes: [],
                    authorized: []
                }
            }
        },
        computed: {
            authorizedProcessorsOptions: function() {
                return [
                    {label:'Hawa Variotec'},
                    {label:'Hawa Frontego'},
                    {label:'Hawa Suono'},
                ]
            },
            calculatedContinentOptions: function() {
                return this.filterData
                    .map(item => item.continent)
                    .filter((value, index, self) => self.indexOf(value) === index && value!=null).sort();
            },
            calculatedTypeOptions: function() {
                return this.filterData
                    .map(item => item.vendorType)
                    .filter((value, index, self) => (value && self.indexOf(value) === index && value.trim() !== '')).sort()
            },
        },
        methods: {
            calculateOptions() {
                this.calculatedCountryOptions = [];
            },
            setFilterFromPath() {

                var urlArr = window.location.href.split('?');

                if(urlArr.length > 1) {
                    var paramsArr = decodeURI(urlArr[1]).split('|');
                    if(paramsArr.length) {
                        var stArr = paramsArr[4] ? paramsArr[4].split(',') : [];
                        var prArr = paramsArr[3] ? paramsArr[3].split(',') : [];

                        this.filter.search = paramsArr[5];
                        this.filter.selectedCountry = paramsArr[0];
                        this.filter.selectedContinent = paramsArr[1];

                        this.filter.search = paramsArr[5];
                        this.filter.zipcode = paramsArr[2];
                        this.filter.selectedTypes = stArr;
                        this.filter.authorized = prArr;
                    }
                }
            },
            buildFilterUri() {
                var currLocation = window.location.href.replace(/\?.*/, '');
                var path = this.getFilterSettingsPath();

                if(this.initialized) {
                    currLocation = currLocation.replace(/~p[0-9]*/,'~p1');
                }

                if(path) {
                    currLocation += '?' + path;
                    window.EventBus.dispatchEvent('historystate-changed', {url: currLocation, filter: path, whitelist: this.whitelist, entity: 'vendor'});
                } else {
                    window.EventBus.dispatchEvent('historystate-changed', {url: currLocation, filter: path, whitelist: this.whitelist, entity: 'vendor'});
                }
                window.history.replaceState(
                    "object or string",
                    "Title",
                    currLocation
                );
            },
            setPathFromFilter() {
                this.buildFilterUri();
            },
            emitEventBusEvents() {
                window.EventBus.dispatchEvent('reload-map-marker', {filter: this.filter, whitelist: this.whitelist});
            },
            getFilterSettingsPath() {

                var pathArray = [];

                pathArray.push(this.filter.selectedCountry);
                pathArray.push(this.filter.selectedContinent);
                pathArray.push(this.filter.zipcode);
                pathArray.push(this.filter.authorized.join(','));
                pathArray.push(this.filter.selectedTypes.join(','));  //type
                pathArray.push(this.filter.search);
                var hasValue = false;
                for(var i in pathArray) {
                    if(pathArray[i]) {
                        hasValue = true;
                    }
                }
                if(!hasValue) {
                    return null;
                }
                var str = pathArray.join('|');
                return encodeURI(pathArray.join('|'));
            },
            resetForm: function() {
              this.filter = {...this.defaultFilter};
              // location.reload();
            },
            sendForm: function() {
                this.setPathFromFilter();
                this.emitEventBusEvents();
                // location.reload();
                return false;
            },
            updateFilter: _debounce(function (value) {
                this.setPathFromFilter();
                this.emitEventBusEvents();
            }, 300),
        },
        watch: {
            'filter.selectedCountry': {
                handler: function(newFilter, oldFilter) {
                    if(this.filter.selectedCountry !== null) {
                        const match = this.filterData.find( _ => {
                            return _.country === newFilter
                        });
                        if(match) {
                            this.filter.selectedContinent = match.continent;
                        }
                    }
                    this.filter.zipcode = '';
                    this.updateFilter();
                }
            },
            'filter.selectedContinent': {
                handler: function(newFilter, oldFilter) {

                    this.calculatedCountryOptions = []
                    var selectedContinent = this.filter.selectedContinent;

                    var matchingObject = this.filterData.find( _ => {
                        return _.continent === selectedContinent && _.country === this.filter.selectedCountry
                    });

                    if(!matchingObject) {
                        this.filter.selectedCountry = '';
                    }

                    const calculatedOptions = this.filterData
                        .filter((value, index, self) => {
                            return (!!this.filter.selectedContinent) ?
                                value.continent === this.filter.selectedContinent : true;
                        })
                        .map(item => item.country)
                        .filter((value, index, self) => self.indexOf(value) === index && value !== null);

                    this.calculatedCountryOptions = calculatedOptions.sort();
                    this.updateFilter();
                },
            },
            'filter.selectedTypes': {
                handler: function(newFilter, oldFilter) {
                    this.updateFilter();
                }
            },
            'filter.authorized': {
                handler: function(newFilter, oldFilter) {
                    this.updateFilter();
                }
            },
            'filter.zipcode': {
                handler: function(newFilter, oldFilter) {
                    this.updateFilter();
                }
            },
            'filter.search': {
                handler: function(newFilter, oldFilter) {
                    this.updateFilter();
                }
            },
        },
        mounted() {
            this.calculatedCountryOptions = this.filterData
                .map(item => item.country)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();

            this.setFilterFromPath();
            setTimeout(() => {
                this.initialized = true;
            }, 500);

            //
        }
    }
</script>
