import * as $ from "jquery";
import axios from "axios"

export class ReferenceList {

    constructor() {

        window.EventBus.addEventListener('historystate-changed', event => {
            if(event.detail.entity !== 'reference') return;
            var url = event.detail.url;
            var urlFilter = event.detail.filter;
            var that = this;
            this.fixPaginationLinks(url, urlFilter);

            $('.m-filteredList').fadeTo('slow', 0.5);
            axios.get(url)
                .then(function (response) {
                    const result = $(response.data).find('.m-filteredList');
                    const resultPagination = $(response.data).find('.m-pagination');

                    if(result) {
                        $('.m-filteredList').html($(result).html());
                    }
                    $('.m-pagination').html(resultPagination.length ? $(resultPagination).html() : '');
                    that.fixPaginationLinks(url, urlFilter);

                    that.setCardHeigths();
                })
                .catch(function (error) {})
                .then(function () {
                    $('.m-filteredList').fadeTo('slow', 1);


                });

        });
    }

    fixPaginationLinks(url, urlFilter) {
        $('.m-pagination__item').each(function(idx, value) {
            var re = /~p(\d)*/i;
            var linkTag = $(value).find('a');
            if(linkTag && $(linkTag).attr('href')) {
                var found = $(linkTag).attr('href').match(re);
                if(found && found[0]) {
                    var oldLink = $(linkTag).attr('href');
                    $(linkTag).attr('href', oldLink.split("?")[0] + '?' + (urlFilter !== null ? urlFilter : ""));
                }
            }
        })
    }

    sortNumber(a,b)    {
        return a - b;
    }

    maxHeight() {

    }

    setCardHeigths() {
        this.maxHeight();
    }
}
