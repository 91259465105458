// import 'bootstrap';
// import { AtomLink, MoleculeSlider } from '@mprofi/standardnodetypes/Resources/Private/JavaScript/extension';
// import './scrollstart';
// import {
//     Footer,
//     MoleculeMegaMenu,

// } from '../Fusion/Root';

// import { EventBus } from './eventbus';
// import { Map } from "../Fusion/Component/Molecule/Map/Map.js"
// import { VendorFilter } from "../Fusion/Component/Molecule/Vendor/Filter/Filter.js"
// import { VendorList } from "../Fusion/Component/Molecule/Vendor/List/List.js"

// import { ReferenceFilter } from "../Fusion/Component/Molecule/Reference/Filter/Filter.js"
// import { ReferenceList } from "../Fusion/Component/Molecule/Reference/List/List.js"

// import { NewsFilter } from "../Fusion/Component/Molecule/News/Filter/Filter.js"
// import { NewsList } from "../Fusion/Component/Molecule/News/List/List.js"

// (function($) {
//     const application = {};

//     application.settings = {
//         inBackend: false,
//     };

//     application.init = () => {
//         window.EventBus = new EventBus;
//         MoleculeMegaMenu.init();
//         MoleculeSlider.init();
//         AtomLink.init();
//         new Map();
//         new footer();
//         new VendorFilter();
//         new VendorList();

//         new ReferenceFilter();
//         new ReferenceList();

//         new NewsFilter();
//         new NewsList();
//     };

//     $(window).on("load", () => {
//         /**
//              * Check if page is scrolled and switch 'scrolling' class for top navigation.
//              * In our case this adds a nice shadow effect.
//              */

//          let $topNavigationWrap = $('.top-navigation-wrap');
//          $(window).on('scrollstart scrollend', function() {
//              $topNavigationWrap.toggleClass('scrolling', $(document).scrollTop() > 0);
//          });
//     });

//     $(() => {
//         application.init();
//     });
// })(jQuery);

// console.log(jQuery);
import jQuery from 'jquery';
import '../Icons/Icons.font';
import './scrollstart';

import {
    MoleculeMegaMenu,
    EventBus,
    Footer,
    Map,
    VendorFilter,
    VendorList,
    ReferenceFilter,
    ReferenceList,
    NewsFilter,
    NewsList,
    CookieConsent,
    Productfinder,
    ReferenceArchive
} from './extension';

import {
    AtomLink,
    MoleculeAccordion,
    MoleculeAccordionHorizontal,
    MoleculeModal,
    MoleculeSlider,
    LayoutCollapsedAugmenter
} from '@mprofi/standardnodetypes/Resources/Private/JavaScript/extension';

import {
  ResponsiveTable
} from '@mprofi/responsivetable/Resources/Private/JavaScript/extension';

import '@mprofi/videostream/Resources/Private/JavaScript/extension';

import {
  ProductBox,
  ShopproductList,
  ShopproductFilter,
  Checkout,
  Cart
} from '@mprofi/magentointegration/Resources/Private/JavaScript/extension';

import {
  FriendlyCaptcha
} from '@mprofi/formbuilder/Resources/Private/JavaScript/extension';

import '@mprofi/maps/Resources/Private/JavaScript/extension';

// jQuery.noConflict();

(function($) {
    $(window).on("load", () => {
        /**
             * Check if page is scrolled and switch 'scrolling' class for top navigation.
             * In our case this adds a nice shadow effect.
             */

         let $topNavigationWrap = $('.top-navigation-wrap');
         $(window).on('scrollstart scrollend', function() {
             $topNavigationWrap.toggleClass('scrolling', $(document).scrollTop() > 0);
         });
    });

    $(() => {
        window.EventBus = new EventBus();
        MoleculeMegaMenu.init();
        AtomLink.init();
        MoleculeAccordion.init();
        MoleculeAccordionHorizontal.init();
        MoleculeModal.init();
        MoleculeSlider.init();
        LayoutCollapsedAugmenter.init();
        ResponsiveTable.init();
        new ProductBox();
        new Map();
        new VendorFilter();
        new VendorList();

        new ReferenceFilter();
        new ReferenceList();

        new ShopproductFilter();
        new ShopproductList();
        new Checkout();
        new Cart();

        new NewsFilter();
        new NewsList();
        new Footer();

        new CookieConsent();

        new Productfinder();
        new ReferenceArchive();

        FriendlyCaptcha.init();
      });
})(jQuery)
