var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "reference-archive-filter-toggle mb-4 pb-2",
        attrs: { id: "reference-archive-list" },
      },
      [
        _c(
          "div",
          {
            staticClass: "container-xl mt-5 mb-5 p-0 filterContainer",
            attrs: { id: "app-filter-news" },
          },
          [
            _c("div", { staticClass: "row grid-row mx-0" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "row responsiveRow" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("span", { staticClass: "filter-label" }, [
                      _vm._v(_vm._s(_vm.translationStrings.labelCountry)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("v-select", {
                        attrs: {
                          placeholder:
                            _vm.translationStrings.placeholderDropdown,
                          options: _vm.calculatedCountryOptions,
                        },
                        model: {
                          value: _vm.filter.selectedCountry,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "selectedCountry", $$v)
                          },
                          expression: "filter.selectedCountry",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row responsiveRow" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("span", { staticClass: "filter-label" }, [
                      _vm._v(_vm._s(_vm.translationStrings.labelLocation)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.selectedLocation,
                          expression: "filter.selectedLocation",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filter.selectedLocation },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filter,
                            "selectedLocation",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row responsiveRow" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("span", { staticClass: "filter-label" }, [
                      _vm._v(_vm._s(_vm.translationStrings.labelObject)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.selectedObject,
                          expression: "filter.selectedObject",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filter.selectedObject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filter,
                            "selectedObject",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row responsiveRow" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("span", { staticClass: "filter-label" }, [
                      _vm._v(_vm._s(_vm.translationStrings.labelBuildingType)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("v-select", {
                        attrs: {
                          placeholder:
                            _vm.translationStrings.placeholderDropdown,
                          options: _vm.calculatedBuildingTypeOptions,
                        },
                        model: {
                          value: _vm.filter.selectedBuildingType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "selectedBuildingType", $$v)
                          },
                          expression: "filter.selectedBuildingType",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row responsiveRow" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("span", { staticClass: "filter-label" }, [
                      _vm._v(_vm._s(_vm.translationStrings.labelBeschlag)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("v-select", {
                        attrs: {
                          placeholder:
                            _vm.translationStrings.placeholderDropdown,
                          options: _vm.calculatedProductOptions,
                        },
                        model: {
                          value: _vm.filter.selectedProduct,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "selectedProduct", $$v)
                          },
                          expression: "filter.selectedProduct",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "row responsiveRow" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-5 col-xs-5" },
                    [
                      _c("span", { staticClass: "filter-label" }, [
                        _vm._v(_vm._s(_vm.translationStrings.labelVorhaben)),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.calculatedProjectOptions, function (item) {
                        return _c("div", { staticClass: "row responsiveRow" }, [
                          _c("div", { staticClass: "col-md-8 col-8" }, [
                            _c("span", { staticClass: "check-entry" }, [
                              _vm._v(_vm._s(item)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-2 col-2" }, [
                            _c(
                              "span",
                              { staticClass: "check-entry-checkbox" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filter.selectedProjects,
                                      expression: "filter.selectedProjects",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: item,
                                    checked: Array.isArray(
                                      _vm.filter.selectedProjects
                                    )
                                      ? _vm._i(
                                          _vm.filter.selectedProjects,
                                          item
                                        ) > -1
                                      : _vm.filter.selectedProjects,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.filter.selectedProjects,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedProjects",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedProjects",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.filter,
                                          "selectedProjects",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-1 col-xs-1" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-5 col-xs-5" },
                    [
                      _c("span", { staticClass: "filter-label" }, [
                        _vm._v(_vm._s(_vm.translationStrings.labelMaterial)),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.calculatedMaterialOptions, function (item) {
                        return _c("div", { staticClass: "row responsiveRow" }, [
                          _c("div", { staticClass: "col-md-8 col-8" }, [
                            _c("span", { staticClass: "check-entry" }, [
                              _vm._v(_vm._s(item)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-2 col-2" }, [
                            _c(
                              "span",
                              { staticClass: "check-entry-checkbox" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filter.selectedMaterials,
                                      expression: "filter.selectedMaterials",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: item,
                                    checked: Array.isArray(
                                      _vm.filter.selectedMaterials
                                    )
                                      ? _vm._i(
                                          _vm.filter.selectedMaterials,
                                          item
                                        ) > -1
                                      : _vm.filter.selectedMaterials,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.filter.selectedMaterials,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedMaterials",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedMaterials",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.filter,
                                          "selectedMaterials",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row grid-row mx-0" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-light btn-block mb-2",
                    attrs: { type: "submit" },
                    on: { click: _vm.resetForm },
                  },
                  [_vm._v(_vm._s(_vm.translationStrings.labelReset))]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "container-xl",
        attrs: { id: "reference-archive-list-list" },
      },
      [
        _c(
          "v-table",
          {
            staticClass: "table table-striped",
            attrs: {
              currentPage: _vm.currentPage,
              pageSize: 12,
              data: _vm.filterData,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              totalPagesChanged: function ($event) {
                _vm.totalPages = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function ({ displayData }) {
                  return _c(
                    "tbody",
                    {},
                    _vm._l(displayData, function (item) {
                      return _c("tr", [
                        _c(
                          "td",
                          { staticClass: "min-view", attrs: { colspan: "7" } },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translationStrings.labelCountry)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                _vm._v(_vm._s(item.country)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translationStrings.labelLocation)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                _vm._v(_vm._s(item.location)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translationStrings.labelObject)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                item.object_url
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.object_url,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.object))]
                                    )
                                  : _c("span", [_vm._v(_vm._s(item.object))]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.translationStrings.labelBuildingType
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                _vm._v(_vm._s(item.buildingType)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translationStrings.labelBeschlag)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                item.product_url
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.product_url,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.product))]
                                    )
                                  : _c("span", [_vm._v(_vm._s(item.product))]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translationStrings.labelMaterial)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                _vm._v(_vm._s(item.material)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-4 col-xs-4 column-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translationStrings.labelVorhaben)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8 col-xs-8" }, [
                                _vm._v(_vm._s(item.project)),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          _vm._v(_vm._s(item.country)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          _vm._v(_vm._s(item.location)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          item.object_url
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.object_url,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(item.object))]
                              )
                            : _c("span", [_vm._v(_vm._s(item.object))]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          _vm._v(_vm._s(item.building_type)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          item.product_url
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.product_url,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(item.product))]
                              )
                            : _c("span", [_vm._v(_vm._s(item.product))]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          _vm._v(_vm._s(item.material)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "max-view" }, [
                          _vm._v(_vm._s(item.project)),
                        ]),
                      ])
                    }),
                    0
                  )
                },
              },
            ]),
          },
          [
            _c("thead", { attrs: { slot: "head" }, slot: "head" }, [
              _c("th", [_vm._v(_vm._s(_vm.translationStrings.labelCountry))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.translationStrings.labelLocation))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.translationStrings.labelObject))]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.translationStrings.labelBuildingType)),
              ]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.translationStrings.labelBeschlag))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.translationStrings.labelMaterial))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.translationStrings.labelVorhaben))]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.filterData.length === 0
          ? _c("div", { staticClass: "no-results" }, [
              _vm._v(
                "\n            Es konnten keine Ergebnisse gefunden werden.\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "smart-pagination",
          {
            attrs: {
              currentPage: _vm.currentPage,
              totalPages: _vm.totalPages,
              maxPageLinks: 3,
              boundaryLinks: true,
              firstText: "<span class='doubleLeft'></span>",
              lastText: "<span class='doubleRight'></span>",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
            },
          },
          [
            _c("span", {
              staticClass: "singleLeft",
              attrs: { slot: "previousIcon" },
              slot: "previousIcon",
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "singleRight",
              attrs: { slot: "nextIcon" },
              slot: "nextIcon",
            }),
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }