import * as $ from 'jquery';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export class Map {

    mapMarkerObjects = [];
    mapObjects = [];
    markerClusterObjects = [];
    mapObjectOptions = [];
    mapBounds = [];
    mapConfigs = [];
    language = 'en';

    constructor() {

        const maps = document.getElementsByClassName("m-map");
        if (maps.length > 0) {
            this.initMapComponents();
        }
    }

    initMapComponents() {

        this.mapObjects = [];
        this.mapObjectOptions = [];
        this.mapMarkerObjects = [];

        const loader = new Loader({
            apiKey: "AIzaSyAN7ArB5bbPmNj-wSXIzAqLuEOnZ5smNao",
            version: "weekly"
        });

        const mapOptions = {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: 4
        };


        // Callback
        loader.loadCallback(e => {
            if (e) {
                console.log(e);
            } else {

                const maps = document.getElementsByClassName("m-map");
                if (maps.length > 0) {

                    for (var i=0; i<maps.length; i++) {

                        this.mapObjectOptions[i] = {
                            dynamicMarkerDataUrl: ''
                        }

                        var center = null;
                        var zoom = 5;

                        if(!center) {
                            center = {lat: 47.387604, lng: 8.800539};
                        }

                        this.mapConfigs[i] = {
                            center: center,
                            zoom: zoom
                        };

                        var bounds = null;
                        var addBounds = true;
                        var marker = $(maps[i]).attr('data-marker').replace(/&quot;/g, '"');
                        var options = JSON.parse($(maps[i]).attr('data-options'));
                        this.language = $(maps[i]).attr('data-language');

                        var mapItems = JSON.parse(marker);


                        // when we filter we would like to show all results within our map
                        if(addBounds) {
                            if(mapItems.length == 1) {
                                // if there is only one result focus on that with city limits
                                this.mapConfigs[i].center = new google.maps.LatLng(parseFloat(mapItems[0].geolat), parseFloat(mapItems[0].geolon));
                                this.mapConfigs[i].zoom = 15;
                            } else {
                                // add each result for the map's bounds
                                this.mapBounds[i] = new google.maps.LatLngBounds();
                                this.mapConfigs[i].center = new google.maps.LatLng(0, 0);
                            }
                        }

                        this.mapObjects[i] = new google.maps.Map(maps[i], this.mapConfigs[i]);
                        this.mapMarkerObjects[i] = [];
                        this.addMarker(i, this.mapObjects[i], mapItems);

                        this.mapObjectOptions[i].dynamicMarkerDataUrl = $(maps[i]).attr('dynamicMarkerDataUrl');
                        this.registerDynamicLoadingEvents(i, this.mapObjects[i], this.mapObjectOptions[i]);

                    }
                }
            }
        });
    }

    registerDynamicLoadingEvents(mapIndx, map, options) {
        if(options.dynamicMarkerDataUrl && options.dynamicMarkerDataUrl != '') {
            window.EventBus.addEventListener('reload-map-marker', event => {
                this.reloadMarker(mapIndx, map, options.dynamicMarkerDataUrl, event.detail)
            });

        }
    }

    clearMarkers(mapIndx) {
        for(var i=0; i<this.mapMarkerObjects[mapIndx].length; i++){
            this.mapMarkerObjects[mapIndx][i].setMap(null);
        }
        this.mapMarkerObjects[mapIndx] = [];
        this.markerClusterObjects[mapIndx].clearMarkers();
        this.markerClusterObjects[mapIndx] = null;
    }

    reloadMarker(mapIndx, map, url, params) {

        var that = this;
        params['language'] = this.language;
        $.ajax({
            url: url,
            data: params,
            cache: false,
            type: "GET",
            success: function(response) {
                that.clearMarkers(mapIndx);
                that.addMarker(mapIndx, that.mapObjects[mapIndx], response);
            },
            error: function(xhr) {

            }
        });
    }

    addMarker(mapIdx, map, mapVendors) {

        var locations = [];
        var zoom = 5;
        var bounds = null;
        var addBounds = true;
        var center = null;

        if(!center) {
            center = {lat: 47.387604, lng: 8.800539};
        }

        if(addBounds) {
            if(mapVendors.length == 1) {
                // if there is only one result focus on that with city limits
                this.mapConfigs[mapIdx].center = new google.maps.LatLng(parseFloat(mapVendors[0].geolat), parseFloat(mapVendors[0].geolon));
                this.mapConfigs[mapIdx].zoom = 15;
            } else {
                // add each result for the map's bounds
                this.mapBounds[mapIdx] = new google.maps.LatLngBounds();
                this.mapConfigs[mapIdx].center = new google.maps.LatLng(0, 0);
            }

        }

        for(var i in mapVendors) {
            var address = mapVendors[i].addresslines.join("<br />").replace(/\n/g,'<br />');
            address = address.replace(mapVendors[i].name + "<br />", "");

            var routenplanerlink = "https://www.google.com/maps/dir/" + mapVendors[i].addresslines.join(',').replace(/\n/g,',').replace('#', '').replace(/&amp;/g, '&');

            var routenplanerstring = 'Zum Routenplaner';
            if(this.language==='en') {
                routenplanerstring = 'For directions';
            }
            if(this.language==='fr') {
                routenplanerstring = 'Calculez votre itinéraire';
            }

            var markerInfo =
                '<div class="vendor-list-map-marker">'
                + '<h4 data-vendorid="'+mapVendors[i].uid+'">' + mapVendors[i].name + '</h4>'
                + '<p><span class="maps-street" style="font-size: 16px;line-height: 24px;">' + address + '</span><br/><br/>'
                + '<a style="color:#003C78" href="' + routenplanerlink + '" class="maps-routing-planner" target="_blank">'+ routenplanerstring + '</a>'
                + '</div>'
            ;


            locations.push([
                markerInfo,
                mapVendors[i].geolat,
                mapVendors[i].geolon
            ]);
        }

        for (i = 0; i < locations.length; i++) {
            var currLL = new google.maps.LatLng(locations[i][1], locations[i][2])
            this.mapMarkerObjects[mapIdx][i] = new google.maps.Marker({
                position: currLL,
                map: map,
                data: {
                    name: locations[i][0]
                }
            });
            this.infoWindow = new google.maps.InfoWindow({
                content: '<div class="scrollFix">'+locations[i][0]+'</div>'
            });

            this.mapMarkerObjects[mapIdx][i].addListener('click', function() {
                $('#vendor-list-map .gm-style-iw button').click();
                if(!this.infoWindow) {
                    this.infoWindow = new google.maps.InfoWindow({
                        content: '<div class="scrollFix">'+this.data.name+'</div>'
                    })
                }
                this.infoWindow.open(map,this);
            });

            if(this.mapBounds[mapIdx]) {
                this.mapBounds[mapIdx].extend(currLL);
            }
        }

        if(this.mapBounds[mapIdx] && mapVendors.length) {
            map.fitBounds(this.mapBounds[mapIdx]);
        }

        const renderer = {
            render({ count, position }, stats) {
                // use d3-interpolateRgb to interpolate between red and blue
                const color = '#484949';
                const color2 = '#ffffff';
                // create svg url with fill color
                const svg = window.btoa(`
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                    <circle fill="${color2}" cx="120" cy="120" opacity="1" r="70" />    
                    <circle fill="${color}" cx="120" cy="120" opacity="1" r="55" />    
                  </svg>`);
                return new google.maps.Marker({
                    label: { text: String(count), color: "white", fontSize: "12px" },
                    position,
                    icon: {
                        url: `data:image/svg+xml;base64,${svg}`,
                        scaledSize: new google.maps.Size(75, 75),
                    },
                    // adjust zIndex to be above other markers
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                })
            }
        }


        this.markerClusterObjects[mapIdx] = new MarkerClusterer({
                map,
                markers: this.mapMarkerObjects[mapIdx],
                renderer
            }
        );

    }


}
