<template>
    <div>
        <div id="reference-archive-list" class="reference-archive-filter-toggle mb-4 pb-2">

            <div id="app-filter-news" class="container-xl mt-5 mb-5 p-0 filterContainer">
                <div class="row grid-row mx-0">
                    <div class="col-md-6">

                        <div class="row responsiveRow">
                            <div class="col-md-4"><span class="filter-label">{{ translationStrings.labelCountry }}</span>
                            </div>
                            <div class="col-md-8">
                                <v-select :placeholder="translationStrings.placeholderDropdown"
                                          v-model="filter.selectedCountry" :options="calculatedCountryOptions"/>
                            </div>
                        </div>

                        <div class="row responsiveRow">
                            <div class="col-md-4"><span class="filter-label">{{ translationStrings.labelLocation }}</span>
                            </div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" v-model="filter.selectedLocation">
                            </div>
                        </div>

                        <div class="row responsiveRow">
                            <div class="col-md-4"><span class="filter-label">{{ translationStrings.labelObject }}</span>
                            </div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" v-model="filter.selectedObject">
                            </div>
                        </div>

                        <div class="row responsiveRow">
                            <div class="col-md-4"><span class="filter-label">{{
                                    translationStrings.labelBuildingType
                                }}</span></div>
                            <div class="col-md-8">
                                <v-select :placeholder="translationStrings.placeholderDropdown"
                                          v-model="filter.selectedBuildingType" :options="calculatedBuildingTypeOptions"/>
                            </div>
                        </div>

                        <div class="row responsiveRow">
                            <div class="col-md-4"><span class="filter-label">{{ translationStrings.labelBeschlag }}</span>
                            </div>
                            <div class="col-md-8">
                                <v-select :placeholder="translationStrings.placeholderDropdown"
                                          v-model="filter.selectedProduct" :options="calculatedProductOptions"/>
                            </div>
                        </div>

                    </div>


                    <div class="col-md-6">

                        <div class="row responsiveRow">
                            <div class="col-md-5 col-xs-5"><span class="filter-label">{{ translationStrings.labelVorhaben }}</span>
                                <div class="row responsiveRow" v-for="item in calculatedProjectOptions">
                                    <div class="col-md-8 col-8"><span class="check-entry">{{ item }}</span></div>
                                    <div class="col-md-2 col-2">
                                    <span class="check-entry-checkbox">
                                        <input type="checkbox" :value="item" v-model="filter.selectedProjects" />
                                    </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-1 col-xs-1"></div>
                            <div class="col-md-5 col-xs-5"><span class="filter-label">{{ translationStrings.labelMaterial }}</span>
                                <div class="row responsiveRow" v-for="item in calculatedMaterialOptions">
                                    <div class="col-md-8 col-8"><span class="check-entry">{{ item }}</span></div>
                                    <div class="col-md-2 col-2">
                                    <span class="check-entry-checkbox">
                                        <input type="checkbox" :value="item" v-model="filter.selectedMaterials" />
                                    </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div class="row grid-row mx-0">
                    <div class="col-md-6">

                        <button type="submit" class="btn btn-outline-light btn-block mb-2" v-on:click="resetForm">{{translationStrings.labelReset}}</button>

                    </div>


                    <div class="col-md-6"></div>

                </div>

            </div>
        </div>
        <div id="reference-archive-list-list" class="container-xl">

            <v-table
                class="table table-striped"
                :currentPage.sync="currentPage"
                :pageSize="12"
                @totalPagesChanged="totalPages = $event"
                :data="filterData">

                <thead slot="head">
                <th>{{ translationStrings.labelCountry }}</th>
                <th>{{ translationStrings.labelLocation }}</th>
                <th>{{ translationStrings.labelObject }}</th>
                <th>{{ translationStrings.labelBuildingType }}</th>
                <th>{{ translationStrings.labelBeschlag }}</th>
                <th>{{ translationStrings.labelMaterial }}</th>
                <th>{{ translationStrings.labelVorhaben }}</th>
                </thead>
                <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="item in displayData">
                    <td class="min-view" colspan="7">
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelCountry }}</div>
                            <div class="col-sm-8 col-xs-8">{{ item.country }}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelLocation }}</div>
                            <div class="col-sm-8 col-xs-8">{{ item.location }}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelObject }}</div>
                            <div class="col-sm-8 col-xs-8"><a v-if="item.object_url" :href="item.object_url"
                                                              target="_blank">{{ item.object }}</a><span
                                v-else>{{ item.object }}</span></div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelBuildingType }}</div>
                            <div class="col-sm-8 col-xs-8">{{ item.buildingType }}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelBeschlag }}</div>
                            <div class="col-sm-8 col-xs-8"><a v-if="item.product_url" :href="item.product_url"
                                                              target="_blank">{{ item.product }}</a><span
                                v-else>{{ item.product }}</span></div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelMaterial }}</div>
                            <div class="col-sm-8 col-xs-8">{{ item.material }}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 column-label">{{ translationStrings.labelVorhaben }}</div>
                            <div class="col-sm-8 col-xs-8">{{ item.project }}</div>
                        </div>
                    </td>

                    <td class="max-view">{{ item.country }}</td>
                    <td class="max-view">{{ item.location }}</td>
                    <td class="max-view"><a v-if="item.object_url" :href="item.object_url"
                                            target="_blank">{{ item.object }}</a><span v-else>{{ item.object }}</span>
                    </td>
                    <td class="max-view">{{ item.building_type }}</td>
                    <td class="max-view"><a v-if="item.product_url" :href="item.product_url"
                                            target="_blank">{{ item.product }}</a><span v-else>{{ item.product }}</span>
                    </td>
                    <td class="max-view">{{ item.material }}</td>
                    <td class="max-view">{{ item.project }}</td>

                </tr>
                </tbody>
            </v-table>

            <div class="no-results" v-if="filterData.length === 0">
                Es konnten keine Ergebnisse gefunden werden.
            </div>

            <smart-pagination
                :currentPage.sync="currentPage"
                :totalPages="totalPages"
                :maxPageLinks="3"
                :boundaryLinks="true"
                firstText="<span class='doubleLeft'></span>"
                lastText="<span class='doubleRight'></span>">
                <span class="singleLeft" slot="previousIcon"></span>
                <span class="singleRight" slot="nextIcon"></span>
            </smart-pagination>
            <br/>
            <br/>
            <br/>


        </div>
    </div>

</template>
<script>
import _debounce from 'lodash/debounce'
import _flatmap from 'lodash/flatMap'

// https://vue-select.org/api/props.html#options
export default {
    name: 'app',
    components: {},
    props: {
        filterBaseData: {
            type: Array,
            default: () => []
        },
        translationStrings: {
            type: Object,
            default: () => {
            }
        }
    },
    data: function () {
        return {
            currentPage: 1,
            totalPages: 0,
            // filteredReferences: this.filterBaseData,
            filter: {
                selectedSortiment: '',
                selectedSegment: '',
                selectedProduct: '',
                selectedCountry: '',
                selectedTopic: '',
                selectedWebinar: '',
                selectedLocation: '',
                selectedObject: '',
                selectedBuildingType: '',
                selectedProjects: [],
                selectedMaterials: [],
            },
            defaultFilter: {
                selectedSortiment: '',
                selectedSegment: '',
                selectedProduct: '',
                selectedCountry: '',
                selectedTopic: '',
                selectedWebinar: '',
                selectedLocation: '',
                selectedObject: '',
                selectedBuildingType: '',
                selectedProjects: [],
                selectedMaterials: [],
            }
        }
    },
    computed: {
        filterData: function () {
            return this.filterBaseData
                .filter((value, index, self) => {
                    return (!this.filter.selectedProjects
                        || this.filter.selectedProjects.length === 0
                        || this.filter.selectedProjects.includes(value.project))
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedMaterials
                        || this.filter.selectedMaterials.length === 0
                        || this.filter.selectedMaterials.includes(value.material))
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedCountry || this.filter.selectedCountry === ''
                        || this.filter.selectedCountry === value.country)
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedProduct || this.filter.selectedProduct === ''
                        || this.filter.selectedProduct === value.product)
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedLocation || this.filter.selectedLocation === ''
                        || value.location.toLowerCase().search(this.filter.selectedLocation.toLowerCase()) !== -1)
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedObject || this.filter.selectedObject === ''
                        || value.object.toLowerCase().search(this.filter.selectedObject.toLowerCase()) !== -1)
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedBuildingType || this.filter.selectedBuildingType === ''
                        || this.filter.selectedBuildingType === value.building_type)
                });

        },
        calculatedCountryOptions: function () {
            return this.filterBaseData
                .map(item => item.country)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedBuildingTypeOptions: function () {
            return this.filterBaseData
                .map(item => item.building_type)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedProjectOptions: function () {
            return this.filterBaseData
                .map(item => item.project)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedMaterialOptions: function () {
            return this.filterBaseData
                .map(item => item.material)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedProductOptions: function () {
            return this.filterBaseData
                .map(item => item.product)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedLocationOptions: function () {
            return this.filterBaseData
                .map(item => item.location)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        }
    },
    methods: {
        resetForm: function () {
            this.filter = {...this.defaultFilter};
            // location.reload();
        },
    },

    watch: {
        'filter': {
            deep: true,
            handler: function (newFilter, oldFilter) {
                // this.setPathFromFilter();
            }
        },
    },
    mounted() {
        // this.updateFilter();
        // this.setFilterFromPath();
    }
}


</script>
