import Component from './ReferenceArchive.vue';
import Vue from 'vue';
import vSelect from 'vue-select'
import SmartTable from 'vuejs-smart-table'

Vue.component('v-select', vSelect)
Vue.use(SmartTable)

export class ReferenceArchive {

    constructor() {
        const templatesContainer = document.getElementById('entity-reference-archive');

        if(templatesContainer) {
            const filterdata = JSON.parse(templatesContainer.getAttribute('data-filter-data'));
            const translationstrings = JSON.parse(templatesContainer.getAttribute('data-translation-strings'));
            new Vue({
                el: '#entity-reference-archive',
                render: h => h(Component, {props: {
                        filterBaseData: filterdata,
                        translationStrings: translationstrings
                    }}),
            });
        }
    }

}

