import * as $ from 'jquery';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/dropdown';

export class MoleculeMegaMenu {
    static init() {
        $('.hawa-mega-menu .dropdown-toggle').mouseenter((event) => {
            // Get window size
            const width = window.innerWidth
                            || document.documentElement.clientWidth
                            || document.body.clientWidth;
        
            // Ignore .dropdown('show') statement, if window size is less than media-breakpoint "lg" (992px)
            if (width > 991) {
                $(event.currentTarget).dropdown('show');
            }
        });
        
        $('.hawa-mega-menu .dropdown').mouseleave((event) => {
            // Get window size
            const width = window.innerWidth
                            || document.documentElement.clientWidth
                            || document.body.clientWidth;
        
            // Ignore .dropdown('show') statement, if window size is less than media-breakpoint "lg" (992px)
            if (width > 991) {
                $('.dropdown-toggle', event.currentTarget).dropdown('hide');
            }
        });
    }
}
