import scrollToElement from 'scroll-to-element';

export class Footer {
    constructor() {
        this._options = {
            topButtonElement: document.querySelector('.topbutton'),
        };

        if (this._options.topButtonElement) {
            this._registerScrollToTopEventListener();
        }
    }

    /**
     * Scroll to content on click
     *
     * @private
     */
    _registerScrollToTopEventListener() {
        const _scrollToElement = document.querySelector('body');
        this._options.topButtonElement.addEventListener('click', event => {
            event.preventDefault();

            scrollToElement(_scrollToElement, {
                duration: 400,
            });
        });
    }
}
