import Component from './Filter.vue';
import Vue from 'vue';
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export class NewsFilter {

    constructor() {
        const templatesContainer = document.getElementById('entity-news-filter');
        if(templatesContainer) {
            const filterdata = JSON.parse(templatesContainer.getAttribute('data-filter-data'));
            const translationstrings = JSON.parse(templatesContainer.getAttribute('data-translation-strings'));
            new Vue({
                el: '#entity-news-filter',
                render: h => h(Component, {props: {
                        filterBaseData: filterdata,
                        translationStrings: translationstrings
                    }}),
            });
        }
    }

}

