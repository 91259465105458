var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "news-filter-toggle mb-0 mb-md-4" }, [
    _c("div", { staticClass: "container-xl mt-1 mb-1 filterContainer" }, [
      _c(
        "div",
        {
          staticClass: "row grid-row mx-0 cursor-pointer",
          on: {
            click: function ($event) {
              _vm.showFilter = !_vm.showFilter
            },
          },
        },
        [
          _c("div", { staticClass: "col-md-3 mb-2 mb-md-0" }, [
            _c("div", { staticClass: "row grid-row" }, [
              _c("div", { staticClass: "news-filter-toggle-btn" }, [
                _vm._v(_vm._s(_vm.translationStrings.labelFilterArticle) + " "),
                _c("span", { staticClass: "nice-select-down active" }),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.showFilter
      ? _c(
          "div",
          {
            staticClass:
              "container-xl mt-3 mb-2 mt-md-5 mb-md-5 filterContainer",
            attrs: { id: "app-filter-news" },
          },
          [
            _c("div", { staticClass: "row grid-row mx-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row grid-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 pl-0 pr-0 pr-md-1" },
                        [
                          _c("strong", { staticClass: "filterLabel" }, [
                            _vm._v(
                              _vm._s(_vm.translationStrings.labelSortiment)
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.sortimentOptions, function (item) {
                        return _c(
                          "div",
                          { staticClass: "col-6 pl-0 pr-3 pr-md-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-sm mt-0",
                                class: {
                                  "btn-primary":
                                    _vm.filter.selectedSortiment.includes(item),
                                  "btn-outline-primary":
                                    !_vm.filter.selectedSortiment.includes(
                                      item
                                    ),
                                },
                                attrs: {
                                  disabled:
                                    !_vm.calculatedSortimentOptions.includes(
                                      item
                                    ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sortimentFilterItemToggle(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5" },
                [
                  _c(
                    "div",
                    { staticClass: "row grid-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 pl-0 pr-0 pr-md-1" },
                        [
                          _c("strong", { staticClass: "filterLabel" }, [
                            _vm._v(
                              _vm._s(_vm.translationStrings.labelSegmente)
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.segmentOptions, function (item) {
                        return _c(
                          "div",
                          { staticClass: "col-6 pl-0 pl-0 pr-3 pr-md-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-sm mt-0",
                                class: {
                                  "btn-primary":
                                    _vm.filter.selectedSegment.includes(item),
                                  "btn-outline-primary":
                                    !_vm.filter.selectedSegment.includes(item),
                                },
                                attrs: {
                                  disabled:
                                    !_vm.calculatedSegmentOptions.includes(
                                      item
                                    ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.segmentFilterItemToggle(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5" },
                [
                  _c(
                    "div",
                    { staticClass: "row grid-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 pl-0 pr-0 pr-md-1" },
                        [
                          _c("strong", { staticClass: "filterLabel" }, [
                            _vm._v(_vm._s(_vm.translationStrings.labelTopics)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.topicOptions, function (item) {
                        return _c(
                          "div",
                          { staticClass: "col-6 pl-0 pl-0 pr-3 pr-md-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-sm mt-0",
                                class: {
                                  "btn-primary":
                                    _vm.filter.selectedTopic.includes(item),
                                  "btn-outline-primary":
                                    !_vm.filter.selectedTopic.includes(item),
                                },
                                attrs: {
                                  disabled:
                                    !_vm.calculatedTopicOptions.includes(item),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.topicFilterItemToggle(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5" },
                [
                  _c("div", { staticClass: "row grid-row" }, [
                    _c("div", { staticClass: "col-md-12 pl-0 pr-0 pr-md-1" }, [
                      _c("strong", { staticClass: "filterLabel" }, [
                        _vm._v(_vm._s(_vm.translationStrings.labelEvents)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 p-0 pr-3 pr-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-sm mt-0",
                          class: {
                            "btn-primary": _vm.filter.event,
                            "btn-outline-primary": !_vm.filter.event,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.eventFilterToggle()
                            },
                          },
                        },
                        [
                          _vm._v("Event"),
                          _c("span", {
                            staticClass:
                              "hawafonticon hawa-calendar-check-regular",
                            attrs: {
                              role: "presentation",
                              "aria-hidden": "true",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12 pl-0 pr-0 pr-md-1" }, [
                      _c("strong", { staticClass: "filterLabel" }, [
                        _vm._v(_vm._s(_vm.translationStrings.labelWebinars)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row grid-row" }, [
                        _c("div", { staticClass: "col-6 pl-0 pr-3 pr-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block btn-sm mt-0",
                              class: {
                                "btn-primary":
                                  _vm.filter.selectedWebinar.includes(
                                    "upcoming"
                                  ),
                                "btn-outline-primary":
                                  !_vm.filter.selectedWebinar.includes(
                                    "upcoming"
                                  ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.webinarFilterItemToggle("upcoming")
                                },
                              },
                            },
                            [_vm._v("Upcoming")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 pl-0 pr-3 pr-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block btn-sm mt-0",
                              class: {
                                "btn-primary":
                                  _vm.filter.selectedWebinar.includes(
                                    "recorded"
                                  ),
                                "btn-outline-primary":
                                  !_vm.filter.selectedWebinar.includes(
                                    "recorded"
                                  ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.webinarFilterItemToggle("recorded")
                                },
                              },
                            },
                            [_vm._v("Recorded")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 pl-0 pr-3 pr-md-2" },
                          [
                            _c("v-select", {
                              staticClass: "filter-dropdown",
                              attrs: {
                                placeholder:
                                  _vm.translationStrings.labelExperte,
                                options: _vm.calculatedExpertOptions,
                              },
                              model: {
                                value: _vm.filter.selectedExpert,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "selectedExpert", $$v)
                                },
                                expression: "filter.selectedExpert",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row grid-row mt-4 mx-0 pr-0 pr-md-5" }, [
              _c("div", { staticClass: "col-md-6 mb-1 mb-md-0 pr-0 pr-md-2" }, [
                _c("div", { staticClass: "row grid-row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-12 col-lg-6 pl-0 pr-3 pr-md-4 pr-lg-4",
                    },
                    [
                      _c("v-select", {
                        staticClass: "filter-dropdown",
                        attrs: {
                          placeholder: _vm.translationStrings.labelYear,
                          options: _vm.calculatedYearOptions,
                        },
                        model: {
                          value: _vm.filter.selectedYear,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "selectedYear", $$v)
                          },
                          expression: "filter.selectedYear",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-primary btn-sm btn-block mb-2 btn-reset",
                          attrs: { type: "submit" },
                          on: { click: _vm.resetForm },
                        },
                        [_vm._v(_vm._s(_vm.translationStrings.labelReset))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }