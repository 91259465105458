<template>
    <div id="app-filter-reference" class="filterContainer">
        <div class="row grid-row ">
            <div class="col-md-3 mb-2 mb-md-0">
                <strong class="filterLabel">{{translationStrings.labelSortiment}}</strong>
                <v-select :placeholder="translationStrings.placeholderDropdown" v-model="filter.selectedSortiment" :options="calculatedSortimentOptions" />
            </div>
            <div class="col-md-3 mb-2 mb-md-0">
                <strong class="filterLabel">{{translationStrings.labelSegment}}</strong>
                <v-select :placeholder="translationStrings.placeholderDropdown" v-model="filter.selectedSegment" :options="calculatedSegmentOptions" />
            </div>
            <div class="col-md-3 mb-2 mb-md-0">
                <strong class="filterLabel">{{translationStrings.labelProductFamily}}</strong>
                <v-select :placeholder="translationStrings.placeholderDropdown" v-model="filter.selectedProductFamily" :options="calculatedProductFamilyOptions" />
            </div>
            <div class="col-md-3 mb-2 mb-md-0">
                <strong class="filterLabel">{{translationStrings.labelCountry}}</strong>
                <v-select :placeholder="translationStrings.placeholderDropdown" v-model="filter.selectedCountry" :options="calculatedCountryOptions" />
            </div>
        </div>
        <div class="row grid-row mt-md-4">
            <div class="col-md-6 mb-1 mb-md-0">
                <button type="submit" class="btn btn-outline-light btn-block mb-2" v-on:click="resetForm">{{translationStrings.labelReset}}</button>
            </div>
            <div class="col-md-6 mb-1 mb-md-0">

            </div>
        </div>
    </div>
</template>
<script>
     import _debounce from 'lodash/debounce'
     import _flatmap from 'lodash/flatMap'

    // https://vue-select.org/api/props.html#options
    export default {
        name: 'app',
        components: {
        },
        props: {
            filterBaseData: {
                type: Array,
                default: () => [
                    {
                        continent: '',
                        country: '',
                        processor: 1,
                        sourcetype: {}
                    }
                ]
            },
            translationStrings: {
                type: Object,
                default: () => {}
            }
        },
        data: function () {
            return {
                initialized: false,
                filter: {
                    selectedSortiment: '',
                    selectedSegment: '',
                    selectedProductFamily: '',
                    selectedCountry: '',
                },
                defaultFilter: {
                    selectedSortiment: '',
                    selectedSegment: '',
                    selectedProductFamily: '',
                    selectedCountry: '',
                }
            }
        },
        computed: {
            filterData: function() {
                return this.filterBaseData
                    .filter((value, index, self) => {
                        return (!this.filter.selectedSortiment || this.filter.selectedSortiment === ''
                            || value.productRanges.indexOf(this.filter.selectedSortiment) !== -1)
                    })
                    .filter((value, index, self) => {
                        return (!this.filter.selectedSegment || this.filter.selectedSegment === '' ||
                            value.segments.indexOf(this.filter.selectedSegment) !== -1)
                    })
                    .filter((value, index, self) => {
                        return (!this.filter.selectedProductFamily || this.filter.selectedProductFamily === '' ||
                            value.productFamilies.indexOf(this.filter.selectedProductFamily) !== -1)
                    })
                    .filter((value, index, self) => {
                        return (!this.filter.selectedCountry || this.filter.selectedCountry === ''
                            || this.filter.selectedCountry === value.country)
                    });
            },
            calculatedSortimentOptions: function() {
                return _flatmap(this.filterData, function duplicate(n) {
                    return n.productRanges;
                }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
            },
            calculatedProductFamilyOptions: function() {
                return _flatmap(this.filterData, function duplicate(n) {
                    return n.productFamilies;
                }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
            },
            calculatedCountryOptions: function() {
                return this.filterData
                    .map(item => item.country)
                    .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
            },
            calculatedSegmentOptions: function() {
                return _flatmap(
                    this.filterData, function duplicate(n) {
                        return n.segments;
                    }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
            },
            placeholderTyp: function() {
                return 'Ich suche nach'
            },
            placeholderCountry: function() {
                return 'Land'
            },
        },
        methods: {
            calculateOptions() {
                // this.calculatedCountryOptions = [];
            },
            setFilterFromPath() {
                var urlArr = window.location.href.split('?');
                if(urlArr.length > 1) {
                    var paramsArr = decodeURI(urlArr[1]).split('|');
                    if(paramsArr.length) {
                        this.filter.selectedSortiment = paramsArr[0];
                        this.filter.selectedSegment = paramsArr[1];
                        this.filter.selectedProductFamily = paramsArr[2];
                        this.filter.selectedCountry = paramsArr[3];
                    }
                }
            },
            buildFilterUri() {
                var currLocation = window.location.href.replace(/\?.*/, '');
                var path = this.getFilterSettingsPath();
                if(path !== null) {
                    currLocation += '?' + path;
                } else {
                    currLocation += '?';
                }
                window.EventBus.dispatchEvent('historystate-changed', {url: currLocation, filter: path, entity: 'reference'});

                window.history.replaceState(
                    "",
                    "",
                    currLocation
                );
            },
            setPathFromFilter() {
                this.buildFilterUri();
            },
            getFilterSettingsPath() {

                var pathArray = [];
                pathArray.push(this.filter.selectedSortiment!='null'?this.filter.selectedSortiment:'');
                pathArray.push(this.filter.selectedSegment!='null'?this.filter.selectedSegment:'');
                pathArray.push(this.filter.selectedProductFamily!='null'?this.filter.selectedProductFamily:'');
                pathArray.push(this.filter.selectedCountry!==null?this.filter.selectedCountry:'');

                var hasValue = false;
                for(var i in pathArray) {
                    if(pathArray[i]) {
                        hasValue = true;
                    }
                }
                if(!hasValue) {
                    return null;
                }
                var str = pathArray.join('|');
                return encodeURI(pathArray.join('|'));
            },
            resetForm: function() {
              this.filter = {...this.defaultFilter};
              // location.reload();
            },
            sendForm: function() {
                this.setPathFromFilter();
                this.emitEventBusEvents();
                // location.reload();
                return false;
            },
            refreshFilterOptions() {
            },
            optionMatchesFilter(option) {

            },
            updateFilter: _debounce(function (value) {
                this.refreshFilterOptions();
                this.setPathFromFilter();
                this.emitEventBusEvents();
            }, 500),
        },

        watch: {
            'filter': {
                deep: true,
                handler: function(newFilter, oldFilter) {
                    this.setPathFromFilter();
                }
            },
        },
        mounted() {
            // this.updateFilter();
            this.setFilterFromPath();
        }
    }
</script>

