var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mt-5 mb-5 filterContainer",
        attrs: { id: "app-filter-productlist" },
      },
      [
        _c("div", { staticStyle: { "margin-top": "1em" } }, [
          _c("div", { staticClass: "row grid-row" }, [
            _c(
              "div",
              { staticClass: "col-md-3 mb-2 mb-md-0" },
              [
                _c("strong", { staticClass: "filterLabel" }, [
                  _vm._v(_vm._s(_vm.translationStrings.labelSortiment)),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    placeholder: _vm.translationStrings.placeholderDropdown,
                    options: _vm.calculatedSortimentOptions,
                  },
                  model: {
                    value: _vm.filter.selectedSortiment,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "selectedSortiment", $$v)
                    },
                    expression: "filter.selectedSortiment",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3 mb-2 mb-md-0" },
              [
                _c("strong", { staticClass: "filterLabel" }, [
                  _vm._v(_vm._s(_vm.translationStrings.labelMaterial)),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    placeholder: _vm.translationStrings.placeholderDropdown,
                    options: _vm.calculatedMaterialOptions,
                  },
                  model: {
                    value: _vm.filter.selectedMaterial,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "selectedMaterial", $$v)
                    },
                    expression: "filter.selectedMaterial",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-3 mb-2 mb-md-0" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-3 mb-2 mb-md-0" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "1em" } }, [
          _c("div", { staticClass: "button-row" }, [
            _c(
              "a",
              {
                staticClass: "btn mb-2",
                class: {
                  "btn-outline-primary": _vm.selectedSection !== "details",
                  "btn-primary": _vm.selectedSection === "details",
                },
                staticStyle: { "margin-bottom": "2em", "font-size": "1rem" },
                attrs: { section: "details" },
                on: {
                  click: function ($event) {
                    _vm.selectedSection = "details"
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.filterdata.labels.labelProduktdetailseite) +
                    " "
                ),
                _c("span", { staticClass: "filterCount" }, [
                  _vm._v("(" + _vm._s(_vm.countDetails) + ")"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn mb-2",
                class: {
                  "btn-outline-primary": _vm.selectedSection !== "katalog",
                  "btn-primary": _vm.selectedSection === "katalog",
                },
                staticStyle: { "margin-bottom": "2em", "font-size": "1rem" },
                attrs: { section: "katalog" },
                on: {
                  click: function ($event) {
                    _vm.selectedSection = "katalog"
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.filterdata.labels.labelKatalogseiten) +
                    " "
                ),
                _c("span", { staticClass: "filterCount" }, [
                  _vm._v("(" + _vm._s(_vm.countKatalog) + ")"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn mb-2",
                class: {
                  "btn-outline-primary": _vm.selectedSection !== "montage",
                  "btn-primary": _vm.selectedSection === "montage",
                },
                staticStyle: { "margin-bottom": "2em", "font-size": "1rem" },
                attrs: { section: "montageanleitungen" },
                on: {
                  click: function ($event) {
                    _vm.selectedSection = "montage"
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.filterdata.labels.labelMontageanleitungen) +
                    " "
                ),
                _c("span", { staticClass: "filterCount" }, [
                  _vm._v("(" + _vm._s(_vm.countMontage) + ")"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn mb-2",
                class: {
                  "btn-outline-primary": _vm.selectedSection !== "cad",
                  "btn-primary": _vm.selectedSection === "cad",
                },
                staticStyle: { "margin-bottom": "2em", "font-size": "1rem" },
                attrs: { section: "cad" },
                on: {
                  click: function ($event) {
                    _vm.selectedSection = "cad"
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.filterdata.labels.labelCad) +
                    " "
                ),
                _c("span", { staticClass: "filterCount" }, [
                  _vm._v("(" + _vm._s(_vm.countCad) + ")"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.selectedSection === "details"
            ? _c("div", { staticClass: "links-details section-div" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-secondary",
                    staticStyle: {
                      "margin-bottom": "2em",
                      "font-size": "1rem",
                    },
                    attrs: {
                      href:
                        _vm.filterdata.uri +
                        "?__l=" +
                        _vm.filterdata.lang +
                        "&__dl=details",
                      target: "_blank",
                      section: "details",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.filterdata.labels.labelDirektlinkButton)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "link-table" },
                  [
                    _c("tr", [
                      _c("td", { staticStyle: { "padding-right": "2em" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.filterdata.labels.labelProduktbezeichnung
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.filterdata.labels.labelDirektlink)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._l(_vm.filteredDetailLinks, function (link, index) {
                      return [
                        _vm.filterItemsByTags(link.items).length > 0
                          ? _c(
                              "tr",
                              {
                                class:
                                  "section-heading heading-" + link.groupid,
                                attrs: { "data-groupid": link.groupid },
                              },
                              [
                                _c("td", [
                                  _c("h4", { staticClass: "mt-3 mb-2" }, [
                                    _vm._v(_vm._s(link.label)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.filterItemsByTags(link.items),
                          function (linkitem, index2) {
                            return _c(
                              "tr",
                              {
                                class:
                                  "section-content item-" + link.groupid + " ",
                                attrs: { "data-visible": "1" },
                              },
                              [
                                _c("td", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.removeUnneededParts(linkitem.label)
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: linkitem.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(linkitem.url))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedSection === "montage"
            ? _c(
                "div",
                { staticClass: "links-montageanleitungen section-div" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-secondary",
                      staticStyle: {
                        "margin-bottom": "2em",
                        "font-size": "1rem",
                      },
                      attrs: {
                        href:
                          _vm.filterdata.uri +
                          "?__l=" +
                          _vm.filterdata.lang +
                          "&__dl=montageanleitungen",
                        section: "montageanleitungen",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.filterdata.labels.labelDirektlinkButton)
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "table",
                    { staticClass: "link-table" },
                    [
                      _c("tr", [
                        _c("td", { staticStyle: { "padding-right": "2em" } }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.filterdata.labels.labelProduktbezeichnung
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.filterdata.labels.labelDirektlink)
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _vm._l(_vm.filteredMontageLinks, function (link, index) {
                        return [
                          _vm.filterItemsByTags(link.items).length > 0
                            ? _c(
                                "tr",
                                {
                                  class:
                                    "section-heading heading-" + link.groupid,
                                  attrs: { "data-groupid": link.groupid },
                                },
                                [
                                  _c("td", [
                                    _c("h4", { staticClass: "mt-3 mb-2" }, [
                                      _vm._v(_vm._s(link.label)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td"),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(
                            _vm.filterItemsByTags(link.items),
                            function (linkitem, index2) {
                              return _c(
                                "tr",
                                {
                                  class:
                                    "section-content item-" +
                                    link.groupid +
                                    " ",
                                  attrs: { "data-visible": "1" },
                                },
                                [
                                  _c("td", { staticClass: "label" }, [
                                    _vm._v(_vm._s(linkitem.label)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: linkitem.url,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(linkitem.url))]
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedSection === "katalog"
            ? _c("div", { staticClass: "links-katalog section-div" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-secondary",
                    staticStyle: {
                      "margin-bottom": "2em",
                      "font-size": "1rem",
                    },
                    attrs: {
                      href:
                        _vm.filterdata.uri +
                        "?__l=" +
                        _vm.filterdata.lang +
                        "&__dl=katalogseiten",
                      section: "katalog",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.filterdata.labels.labelDirektlinkButton)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "link-table" },
                  [
                    _c("tr", [
                      _c("td", { staticStyle: { "padding-right": "2em" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.filterdata.labels.labelProduktbezeichnung
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.filterdata.labels.labelDirektlink)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _vm._l(_vm.filteredKatalogLinks, function (link, index) {
                      return [
                        _vm.filterItemsByTags(link.items).length > 0
                          ? _c(
                              "tr",
                              {
                                class:
                                  "section-heading heading-" + link.groupid,
                                attrs: { "data-groupid": link.groupid },
                              },
                              [
                                _c("td", [
                                  _c("h4", { staticClass: "mt-3 mb-2" }, [
                                    _vm._v(_vm._s(link.label)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.filterItemsByTags(link.items),
                          function (linkitem, index2) {
                            return _c(
                              "tr",
                              {
                                class:
                                  "section-content item-" + link.groupid + " ",
                                attrs: { "data-visible": "1" },
                              },
                              [
                                _c("td", { staticClass: "label" }, [
                                  _vm._v(_vm._s(linkitem.label)),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: linkitem.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(linkitem.url))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedSection === "cad"
            ? _c("div", { staticClass: "links-cad section-div" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-secondary",
                    staticStyle: {
                      "margin-bottom": "2em",
                      "font-size": "1rem",
                    },
                    attrs: {
                      href:
                        _vm.filterdata.uri +
                        "?__l=" +
                        _vm.filterdata.lang +
                        "&__dl=cad",
                      section: "cad",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.filterdata.labels.labelDirektlinkButton)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "link-table" },
                  [
                    _c("tr", [
                      _c("td", { staticStyle: { "padding-right": "2em" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.filterdata.labels.labelProduktbezeichnung
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.filterdata.labels.labelDirektlink)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(_vm.filteredCadLinks, function (link, index) {
                      return [
                        _vm.filterItemsByTags(link.items).length > 0
                          ? _c(
                              "tr",
                              {
                                class:
                                  "section-heading heading-" + link.groupid,
                                attrs: { "data-groupid": link.groupid },
                              },
                              [
                                _c("td", [
                                  _c("h4", { staticClass: "mt-3 mb-2" }, [
                                    _vm._v(_vm._s(link.label)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.filterItemsByTags(link.items),
                          function (linkitem, index2) {
                            return _c(
                              "tr",
                              {
                                class:
                                  "section-content item-" + link.groupid + " ",
                                attrs: { "data-visible": "1" },
                              },
                              [
                                _c("td", { staticClass: "label" }, [
                                  _vm._v(_vm._s(linkitem.label)),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.filterdata.uri +
                                          "?__l=" +
                                          _vm.filterdata.lang +
                                          "&__d=" +
                                          linkitem.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(linkitem.url))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v(" ")]),
      _vm._v(" "),
      _c("td", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v(" ")]),
      _vm._v(" "),
      _c("td", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v(" ")]),
      _vm._v(" "),
      _c("td", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v(" ")]),
      _vm._v(" "),
      _c("td", [_vm._v(" ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }