import * as $ from "jquery";

export class CookieConsent {

    constructor() {
        // wurde disabled
        if($('.klaro.we_cookie_consent').length === 0) {
            var manager = klaro.getManager();
            manager.changeAll(true);
            manager.saveAndApplyConsents();
        } else {
            this.init();
        }
    }

    init() {

        if (document.cookie.indexOf('cookie_punch') == -1 ) {
            $('.klaro.we_cookie_consent').show();
        } else {
            $('.--sticky_btn').show();
        }

        $('.cm-btn.cm-btn-success').click( function() {
            var manager = klaro.getManager();
            manager.changeAll(true);
            manager.saveAndApplyConsents();
            $('.cookie-notice').fadeOut('fast');
            $('.--sticky_btn').show();
        })

        $('.cm-link.cn-learn-more').click( function() {
            klaro.show();
            $('.cookie-notice').fadeOut('fast');
            $('.cm-content').each(function() {
                $(this).addClass('expanded');
            })
        });


        $(document).on('click', '.cm-btn.cn-decline', function() {
            $('.--sticky_btn').show();
        });
        $(document).on('click', '.cm-btn.cm-btn-accept', function() {
            $('.--sticky_btn').show();
        });
        $(document).on('click', '.cm-btn.cm-btn-accept-all', function() {
            $('.--sticky_btn').show();
        })
    }

}

