<template>
    <div class="news-filter-toggle mb-0 mb-md-4">

        <div class="container-xl mt-1 mb-1 filterContainer">
            <div class="row grid-row mx-0 cursor-pointer" v-on:click="showFilter=!showFilter">
                <div class="col-md-3 mb-2 mb-md-0">
                    <div class="row grid-row">
                        <div class="news-filter-toggle-btn">{{translationStrings.labelFilterArticle}} <span class="nice-select-down active"></span></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showFilter" id="app-filter-news" class="container-xl mt-3 mb-2 mt-md-5 mb-md-5 filterContainer">
            <div class="row grid-row mx-0">
                <div class="col-12 col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5">

                    <div class="row grid-row">
                        <div class="col-md-12 pl-0 pr-0 pr-md-1"><strong class="filterLabel">{{translationStrings.labelSortiment}}</strong></div>
                        <div class="col-6 pl-0 pr-3 pr-md-2" v-for="item in sortimentOptions">
                            <button
                                :disabled="!calculatedSortimentOptions.includes(item)"
                                v-on:click="sortimentFilterItemToggle(item)"
                                v-bind:class="{ 'btn-primary': filter.selectedSortiment.includes(item), 'btn-outline-primary': !filter.selectedSortiment.includes(item) }"
                                class="btn btn-block btn-sm mt-0">{{item}}</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5">
                    <div class="row grid-row">
                        <div class="col-md-12 pl-0 pr-0 pr-md-1"><strong class="filterLabel">{{translationStrings.labelSegmente}}</strong></div>
                        <div class="col-6 pl-0 pl-0 pr-3 pr-md-2" v-for="item in segmentOptions">
                            <button
                                :disabled="!calculatedSegmentOptions.includes(item)"
                                v-on:click="segmentFilterItemToggle(item)"
                                v-bind:class="{ 'btn-primary': filter.selectedSegment.includes(item), 'btn-outline-primary': !filter.selectedSegment.includes(item) }"
                                class="btn btn-block btn-sm mt-0">{{item}}</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5">

                    <div class="row grid-row">
                        <div class="col-md-12 pl-0 pr-0 pr-md-1"><strong class="filterLabel">{{translationStrings.labelTopics}}</strong></div>
                        <div class="col-6 pl-0 pl-0 pr-3 pr-md-2" v-for="item in topicOptions">
                            <button
                                :disabled="!calculatedTopicOptions.includes(item)"
                                v-on:click="topicFilterItemToggle(item)"
                                v-bind:class="{ 'btn-primary': filter.selectedTopic.includes(item), 'btn-outline-primary': !filter.selectedTopic.includes(item) }"
                                class="btn btn-block btn-sm mt-0">{{item}}</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-3 mb-2 mb-md-0 pr-0 pr-md-5">

                    <div class="row grid-row">
                        <div class="col-md-12 pl-0 pr-0 pr-md-1"><strong class="filterLabel">{{translationStrings.labelEvents}}</strong></div>

                        <div class="col-6 p-0 pr-3 pr-md-2">
                            <button
                                v-on:click="eventFilterToggle()"
                                v-bind:class="{ 'btn-primary': filter.event, 'btn-outline-primary': !filter.event }"
                                class="btn btn-block btn-sm mt-0">Event<span role="presentation" aria-hidden="true" class="hawafonticon hawa-calendar-check-regular"></span></button>
                        </div>

                        <div class="col-md-12 pl-0 pr-0 pr-md-1"><strong class="filterLabel">{{translationStrings.labelWebinars}}</strong></div>

                        <div class="col-12">
                            <div class="row grid-row">
                                <div class="col-6 pl-0 pr-3 pr-md-2">
                                    <button
                                        v-on:click="webinarFilterItemToggle('upcoming')"
                                        v-bind:class="{ 'btn-primary': filter.selectedWebinar.includes('upcoming'), 'btn-outline-primary': !filter.selectedWebinar.includes('upcoming') }"
                                        class="btn btn-block btn-sm mt-0">Upcoming</button>
                                </div>
                                <div class="col-6 pl-0 pr-3 pr-md-2">
                                    <button
                                        v-on:click="webinarFilterItemToggle('recorded')"
                                        v-bind:class="{ 'btn-primary': filter.selectedWebinar.includes('recorded'), 'btn-outline-primary': !filter.selectedWebinar.includes('recorded') }"
                                        class="btn btn-block btn-sm mt-0">Recorded</button>
                                </div>
                                <div class="col-12 pl-0 pr-3 pr-md-2">
                                    <v-select :placeholder="translationStrings.labelExperte" class="filter-dropdown" v-model="filter.selectedExpert" :options="calculatedExpertOptions" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row grid-row mt-4 mx-0 pr-0 pr-md-5">
                <div class="col-md-6 mb-1 mb-md-0 pr-0 pr-md-2">
                    <div class="row grid-row">
                        <div class="col-md-12 col-lg-6 pl-0 pr-3 pr-md-4 pr-lg-4">
                            <v-select class="filter-dropdown" :placeholder="translationStrings.labelYear" v-model="filter.selectedYear" :options="calculatedYearOptions" />
                            <button type="submit" class="btn btn-outline-primary btn-sm btn-block mb-2 btn-reset" v-on:click="resetForm">{{translationStrings.labelReset}}</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _debounce from 'lodash/debounce'
import _flatmap from 'lodash/flatMap'

// https://vue-select.org/api/props.html#options
export default {
    name: 'app',
    components: {
    },
    props: {
        filterBaseData: {
            type: Array,
            default: () => [
                {
                    continent: '',
                    country: '',
                    processor: 1,
                    sourcetype: {}
                }
            ]
        },
        translationStrings: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            initialized: false,
            showFilter: false,
            filter: {
                selectedSortiment: [],
                selectedSegment: [],
                selectedTopic: [],
                selectedWebinar: [],
                selectedYear: '',
                selectedExpert: '',
                event: 0
            },
            defaultFilter: {
                selectedSortiment: [],
                selectedSegment: [],
                selectedTopic: [],
                selectedWebinar: [],
                selectedYear: '',
                selectedExpert: '',
                event: 0
            }
        }
    },
    computed: {
        filterData: function() {
            let checker = (arr, target) => target.every(v => arr.includes(v));


            return this.filterBaseData
                .filter((value, index, self) => {
                    return (!this.filter.selectedSortiment
                        || this.filter.selectedSortiment.length === 0
                        || checker(value.productRanges, this.filter.selectedSortiment))
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedSegment
                        || this.filter.selectedSegment.length === 0
                        || checker(value.segments, this.filter.selectedSegment))
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedTopic
                        || this.filter.selectedTopic.length === 0
                        || checker(value.topics, this.filter.selectedTopic))
                })
                .filter((value, index, self) => {
                    return (!this.filter.selectedYear || this.filter.selectedYear === ''
                        || this.filter.selectedYear === value.year)
                });
        },
        calculatedSortimentOptions: function() {
            return _flatmap(this.filterData, function duplicate(n) {
                return n.productRanges;
            }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        sortimentOptions: function() {
            return _flatmap(this.filterBaseData, function duplicate(n) {
                return n.productRanges;
            }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedYearOptions: function() {
            return this.filterData
                .map(item => item.year)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort().reverse();
        },
        calculatedExpertOptions: function() {
            return this.filterData
                .map(item => item.expert)
                .filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedSegmentOptions: function() {
            return _flatmap(
                this.filterData, function duplicate(n) {
                    return n.segments;
                }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        calculatedTopicOptions: function() {
            return _flatmap(
                this.filterData, function duplicate(n) {
                    return n.topics;
                }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        segmentOptions: function() {
            return _flatmap(
                this.filterBaseData, function duplicate(n) {
                    return n.segments;
                }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        topicOptions: function() {
            return _flatmap(
                this.filterBaseData, function duplicate(n) {
                    return n.topics;
                }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
        },
        placeholderTyp: function() {
            return 'Ich suche nach'
        },
        placeholderCountry: function() {
            return 'Land'
        },
    },
    methods: {
        sortimentFilterItemToggle(item) {
            if(this.filter.selectedSortiment.includes(item)) {
                this.filter.selectedSortiment = this.filter.selectedSortiment.filter((value, index, self) => value !==item);
            } else {
                this.filter.selectedSortiment.push(item);
            }
        },
        segmentFilterItemToggle(item) {
            if(this.filter.selectedSegment.includes(item)) {
                this.filter.selectedSegment = this.filter.selectedSegment.filter((value, index, self) => value !==item);
            } else {
                this.filter.selectedSegment.push(item);
            }
        },
        topicFilterItemToggle(item) {
            if(this.filter.selectedTopic.includes(item)) {
                this.filter.selectedTopic = this.filter.selectedTopic.filter((value, index, self) => value !==item);
            } else {
                this.filter.selectedTopic.push(item);
            }
        },
        webinarFilterItemToggle(item) {
            if(this.filter.selectedWebinar.includes(item)) {
                this.filter.selectedWebinar = this.filter.selectedWebinar.filter((value, index, self) => value !==item);
            } else {
                this.filter.selectedWebinar.push(item);
            }
        },
        eventFilterToggle() {
            if(this.filter.event === 1) {
                this.filter.event = 0;
            } else {
                this.filter.event = 1;
            }
        },
        calculateOptions() {
            // this.calculatedCountryOptions = [];
        },
        setFilterFromPath() {
            var urlArr = window.location.href.split('?');
            if(urlArr.length > 1) {
                var paramsArr = decodeURI(urlArr[1]).split('|');
                if(paramsArr.length) {
                    this.filter.selectedSortiment = (paramsArr[0] && paramsArr[0].length>0) ? paramsArr[0].split(',') : [];
                    this.filter.selectedSegment = (paramsArr[1] && paramsArr[1].length>0) ? paramsArr[1].split(',') : [];
                    this.filter.selectedTopic = (paramsArr[2] && paramsArr[2].length>0) ? paramsArr[2].split(',') : [];
                    this.filter.selectedYear = paramsArr[3];
                    this.filter.event = (paramsArr[4] && paramsArr[4] === 'events') ? 1 : 0;
                    this.filter.selectedWebinar = (paramsArr[5] && paramsArr[5].length>0) ? paramsArr[5].split(',') : [];
                    this.filter.selectedExpert = (paramsArr[6] && paramsArr[6]!='') ? paramsArr[6] : [];
                }
            }
        },
        buildFilterUri() {
            var currLocation = window.location.href.replace(/\?.*/, '');
            var path = this.getFilterSettingsPath();
            if(path !== null) {
                currLocation += '?' + path;
            } else {
                currLocation += '?';
            }
            window.EventBus.dispatchEvent('historystate-changed', {url: currLocation, filter: path, entity: 'news'});
            window.history.replaceState(
                "",
                "",
                currLocation
            );
        },
        setPathFromFilter() {
            this.buildFilterUri();
        },
        getFilterSettingsPath() {

            var pathArray = [];
            pathArray.push(this.filter.selectedSortiment!='null'?this.filter.selectedSortiment:'');
            pathArray.push(this.filter.selectedSegment!='null'?this.filter.selectedSegment:'');
            pathArray.push(this.filter.selectedTopic!='null'?this.filter.selectedTopic:'');
            pathArray.push(this.filter.selectedYear!==null?this.filter.selectedYear:'');
            pathArray.push(this.filter.event!==0?'events':'');
            pathArray.push(this.filter.selectedWebinar!='null'?this.filter.selectedWebinar:'');
            pathArray.push(this.filter.selectedExpert!==null?this.filter.selectedExpert:'');

            var hasValue = false;
            for(var i in pathArray) {
                if(pathArray[i]) {
                    hasValue = true;
                }
            }
            if(!hasValue) {
                return null;
            }
            var str = pathArray.join('|');
            return encodeURI(pathArray.join('|'));
        },
        resetForm: function() {
            this.filter = {
                selectedSortiment: [],
                selectedSegment: [],
                selectedTopic: [],
                selectedWebinar: [],
                selectedYear: '',
                selectedExpert: '',
                event: 0
            };
            // location.reload();
        },
        sendForm: function() {
            this.setPathFromFilter();
            this.emitEventBusEvents();
            // location.reload();
            return false;
        },
        refreshFilterOptions() {
        },
        optionMatchesFilter(option) {

        },
        updateFilter: _debounce(function (value) {
            this.refreshFilterOptions();
            this.setPathFromFilter();
            this.emitEventBusEvents();
        }, 500),
    },

    watch: {
        'filter': {
            deep: true,
            handler: function(newFilter, oldFilter) {
                this.setPathFromFilter();
            }
        },
    },
    mounted() {
        // this.updateFilter();
        this.setFilterFromPath();
    }
}
</script>

