var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filterContainer", attrs: { id: "app-filter-reference" } },
    [
      _c("div", { staticClass: "row grid-row" }, [
        _c(
          "div",
          { staticClass: "col-md-3 mb-2 mb-md-0" },
          [
            _c("strong", { staticClass: "filterLabel" }, [
              _vm._v(_vm._s(_vm.translationStrings.labelSortiment)),
            ]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                placeholder: _vm.translationStrings.placeholderDropdown,
                options: _vm.calculatedSortimentOptions,
              },
              model: {
                value: _vm.filter.selectedSortiment,
                callback: function ($$v) {
                  _vm.$set(_vm.filter, "selectedSortiment", $$v)
                },
                expression: "filter.selectedSortiment",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3 mb-2 mb-md-0" },
          [
            _c("strong", { staticClass: "filterLabel" }, [
              _vm._v(_vm._s(_vm.translationStrings.labelSegment)),
            ]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                placeholder: _vm.translationStrings.placeholderDropdown,
                options: _vm.calculatedSegmentOptions,
              },
              model: {
                value: _vm.filter.selectedSegment,
                callback: function ($$v) {
                  _vm.$set(_vm.filter, "selectedSegment", $$v)
                },
                expression: "filter.selectedSegment",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3 mb-2 mb-md-0" },
          [
            _c("strong", { staticClass: "filterLabel" }, [
              _vm._v(_vm._s(_vm.translationStrings.labelProductFamily)),
            ]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                placeholder: _vm.translationStrings.placeholderDropdown,
                options: _vm.calculatedProductFamilyOptions,
              },
              model: {
                value: _vm.filter.selectedProductFamily,
                callback: function ($$v) {
                  _vm.$set(_vm.filter, "selectedProductFamily", $$v)
                },
                expression: "filter.selectedProductFamily",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3 mb-2 mb-md-0" },
          [
            _c("strong", { staticClass: "filterLabel" }, [
              _vm._v(_vm._s(_vm.translationStrings.labelCountry)),
            ]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                placeholder: _vm.translationStrings.placeholderDropdown,
                options: _vm.calculatedCountryOptions,
              },
              model: {
                value: _vm.filter.selectedCountry,
                callback: function ($$v) {
                  _vm.$set(_vm.filter, "selectedCountry", $$v)
                },
                expression: "filter.selectedCountry",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row grid-row mt-md-4" }, [
        _c("div", { staticClass: "col-md-6 mb-1 mb-md-0" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-light btn-block mb-2",
              attrs: { type: "submit" },
              on: { click: _vm.resetForm },
            },
            [_vm._v(_vm._s(_vm.translationStrings.labelReset))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6 mb-1 mb-md-0" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }