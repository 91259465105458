import {autocomplete} from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import {createLocalStorageRecentSearchesPlugin} from '@algolia/autocomplete-plugin-recent-searches';
import * as $ from "jquery";


function debouncePromise(fn, time) {
  let timerId = undefined;

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}
const debounced = debouncePromise((items) => Promise.resolve(items), 400);
let searchUriBase = '';

let foundTop = false;

let livesearchtrigger = document.getElementsByClassName('livesearchtrigger')
let live_results = $('.live-results')
let searchCloseButton = $('.liveSearchHeaderBox .searchCloseButton') // document.getElementsByClassName('searchCloseButton')
let aaInputFields = document.getElementsByClassName('aa-Input')

let translations = {
  livesearchtitle: 'Please enter a search term:',
  livesearchplaceholder: 'Search',
  livesearchopenpage: 'Open page',
  livesearchshowmore: 'Show more results',
  livesearchnothingfound: 'We are sorry, but we could not find anything for the search you requested. Please try again with a different search term or contact us by e-mail at info@hawa.com.',
}


if(searchCloseButton) {
  searchCloseButton.click(function (e) {
    e.preventDefault();
    let liveSearchHeaderBox = $('.liveSearchHeaderBox');
    if(liveSearchHeaderBox) {
      liveSearchHeaderBox.hide();
      $('body').removeClass('openLiveSearchHeaderBox');
    }
  })
}

if(livesearchtrigger[0]) {
  livesearchtrigger[0].addEventListener("click", function (e) {
    e.preventDefault();

    // wir sind auf der such detail seite
    if(live_results.length > 1) {
      return;
    }

    let liveSearchHeaderBox = document.getElementsByClassName('liveSearchHeaderBox');
    if(liveSearchHeaderBox[0]) {
      searchUriBase = liveSearchHeaderBox[0].dataset.searchLink
      translations.livesearchshowmore = liveSearchHeaderBox[0].dataset.translationsShowmore
      translations.livesearchnothingfound = liveSearchHeaderBox[0].dataset.translationsNothingfound
      translations.livesearchplaceholder = liveSearchHeaderBox[0].dataset.translationsPlaceholder
      translations.livesearchtitle = liveSearchHeaderBox[0].dataset.translationsSearchtitle
      translations.livesearchopenpage = liveSearchHeaderBox[0].dataset.translationsOpenpage


      // Show an element
      var show = function (elem) { elem.style.display = 'block'; };
      var hide = function (elem) { elem.style.display = 'none'; };
      if (window.getComputedStyle(liveSearchHeaderBox[0]).display === 'block') {
        hide(liveSearchHeaderBox[0]);
        $('body').removeClass('openLiveSearchHeaderBox');
      } else {
        show(liveSearchHeaderBox[0]);
        aaInputFields[0].focus();
        $('body').addClass('openLiveSearchHeaderBox');
      }
    }
  });

  // mobile version
  if(livesearchtrigger[1]) {
    livesearchtrigger[1].addEventListener("click", function (e) {
      e.preventDefault();

      let liveSearchHeaderBox = document.getElementsByClassName('liveSearchHeaderBox');
      if(liveSearchHeaderBox[0]) {
        searchUriBase = liveSearchHeaderBox[0].dataset.searchLink
        translations.livesearchshowmore = liveSearchHeaderBox[0].dataset.translationsShowmore
        translations.livesearchnothingfound = liveSearchHeaderBox[0].dataset.translationsNothingfound
        translations.livesearchplaceholder = liveSearchHeaderBox[0].dataset.translationsPlaceholder
        translations.livesearchtitle = liveSearchHeaderBox[0].dataset.translationsSearchtitle
        translations.livesearchopenpage = liveSearchHeaderBox[0].dataset.translationsOpenpage
        // Show an element
        var show = function (elem) { elem.style.display = 'block'; };
        var hide = function (elem) { elem.style.display = 'none'; };
        if (window.getComputedStyle(liveSearchHeaderBox[0]).display === 'block') {
          hide(liveSearchHeaderBox[0]);
          $('body').removeClass('openLiveSearchHeaderBox');
        } else {
          show(liveSearchHeaderBox[0]);
          aaInputFields[0].focus();
          $('body').addClass('openLiveSearchHeaderBox');
        }
      }
    });
  }

}

let autocomplete_element = $('.autocomplete-element') // document.getElementById('autocomplete')

if (autocomplete_element) {
  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
  });

  let autocompleteElement = autocomplete_element.length === 2 ? autocomplete_element[1] : autocomplete_element[0]
  let source = autocompleteElement.dataset.autocompleteSource;
  let products_source = autocompleteElement.dataset.autocompleteProductsSource;
  let live_result_source = autocompleteElement.dataset.ajaxSource;

  const updateLiveResults = query => {
    if (query.length > 3) {
      let live_url = live_result_source + "&q=" + query
      // console.log({live_url})
      fetch(live_url)
        .then((response) => {
          response.text().then((result) => {
            $('.live-results').html(result)
          });
        });
    }
  };

  autocomplete({
    container: autocompleteElement,
    panelContainer: '#autocompletePanel',
    detachedMediaQuery: 'none',
    plugins: [
      // recentSearchesPlugin
    ],
    openOnFocus: false,
    initialState: {
      query: autocompleteElement.dataset.value
    },
    placeholder: autocompleteElement.dataset.search,
    // https://www.algolia.com/doc/ui-libraries/autocomplete/guides/creating-a-multi-column-layout/
    render({elements, render, html}, root) {
      const {completions, suggestions, products} = elements;
      // const {products, recentSearchesPlugin} = elements;
      let title = '';
      let buttonText = translations.livesearchshowmore;
      let moreButton = html``;
      let query = '';


      if($(autocompleteElement).find('input')[0]) {
        query = $(autocompleteElement).find('input')[0].value;
      }

      if(foundTop) {
        // title = html`<h4 style="margin-top: 1em;font-style: italic;">Top Treffer</h4>`;
        title = html``;
        moreButton = html`<div class="showMoreSearchResults" style="text-align: center">
          <a class="btn-outline-primary btn btn--with-icon btn--with-icon-right link-animated" href="${searchUriBase}?search=${query}" target="_self">
            ${buttonText}
            <span class="icon hawafonticon hawa-arrow-right icon-animated--right icon-animated icon--right"></span></a>
        </div>`
      } else {

        var textArea = document.createElement('textarea');
        textArea.innerHTML = translations.livesearchnothingfound;

        let msg = $("<textarea/>")
          .html(translations.livesearchnothingfound)
          .text();
        title = translations.livesearchnothingfound;
        title = html`<div class="noresultmsg"><br /><br />${title}</div>`
      }
      render(
        html`
          <div className="aa-PanelLayout aa-Panel--scrollable">
            <div className="aa-PanelSections">
              <div style="display: none" className="aa-PanelSection--full aa-PanelSection--searchwords">${completions} ${suggestions}</div>
              <div className="aa-PanelSection--full aa-PanelSection--top">
                ${title}
                ${products}
                ${moreButton}
              </div>
            </div>
          </div>`,
        root
      );
    },
    getSources({query}) {

      if(live_results.length === 2) {
        return [];
      }

      let url = source + "&term=" + query
      var result_json = fetch(url).then((response) => response.json())

      if(live_results.length === 2) {
        // updateLiveResults(query);
      }

      return debounced([
        {
          sourceId: 'completions',
          getItems() {
            return result_json.then((result) => {
              return result.autocomplete
            });
          },
          getItemInputValue({item}) {
            return item.key;
          },
          templates: {
            noResults() {
              return null;
            },
            getItemUrl({item}) {
              return `${searchUriBase}?search=${item.key}`
            },
            item({item, html}) {
              return html`
                  <div class="live-search-item live-search-item--site live-search-item--site-searchword">
                    <div class="live-search-item__content">
                      <div class="live-search-item__title_">
                        <span class="aa-ItemLink">${item.key}1</span>
                      </div>
                    </div>
                  </div>
                `;

              //return html`${item.key}`;
            }
          }
        },
        {
          sourceId: 'suggestions',
          getItems() {
            return result_json.then((result) => {
              return result.suggest;
            });
          },
          getItemInputValue({item}) {
            return item.text;
          },
          getItemUrl({item}) {
            return `${searchUriBase}?search=${item.text}`;
          },
          templates: {
            noResults() {
              return '' // NoResult
            },
            item({item, html}) {
              return html`
                  <div class="live-search-item live-search-item--site live-search-item--site-searchword">
                    <div class="live-search-item__content">
                      <div class="live-search-item__title_">
                        <span class="aa-ItemLink">${item.key}</span>
                      </div>
                    </div>
                  </div>
                `;
              // return html`${item.text}`;
            }
          }
        },
        {
          sourceId: 'products',
          getItems() {
            let products_url = products_source + "&term=" + query
            return fetch(products_url).then((response) => response.json()).then((result) => {
              if(result.hits.length > 0) foundTop = true;
              else foundTop = false;
              return result.hits.filter((element,index) => index < 6);
            })
          },
          getItemInputValue({item}) {
            if (item._source.neos_type != 'product') {
              return `${item._source.title}`
            } else {
              return `${item._source.name}`
            }
          },
          getItemUrl({item}) {
            if (item._source.neos_type != 'product') {
              return `${searchUriBase}?search=${item._source.title}`
            } else {
              return `${item._source.url}`
            }
          },
          // onSelect({item, state}){
          //   state.query = item._source.name
          //   state.isOpen = false;
          // },
          templates: {
            noResults() {
              return ''
            },
            item({item, html}) {
              if (item._source.neos_type != 'product') {

                let description = item._source.neos_fulltext.text ? html`
                  <div class="live-search-item__description">${item._source.neos_fulltext.text.substring(0, 255)}
                  </div>` : '';

                let itemlink = item.uri != '' ? item.uri : searchUriBase + '?search=' + item._source.title;

                return html`
                  <div class="live-search-item live-search-item--site">
                    <div class="live-search-item__content">
                      <div class="live-search-item__title">
                        <a class="aa-ItemLink" href="${itemlink}">${item._source.title}</a>
                      </div>
                      <a class="aa-ItemLink aa-ItemLink-text" href="${itemlink}">${description}</a>
                      <div class="live-search-item__dividor"></div>
                    </div>
                  </div>
                `;
              } else {
                const url = new URL(item._source.url);
                let image = item._source.image ? html`
                  <div class="live-search-item__image">
                    <img src="${url.origin}${item._source.image}" alt="${item._source.name}"/></div>` : '';
                let description = item._source.description ? html`
                  <div class="live-search-item__description">${item._source.description.substring(0, 255)}
                  </div>` : '';
                return html`
                  <div class="live-search-item live-search-item--product">
                    <div class="live-search-item__content">
                      <div class="live-search-item__title">
                        <a class="aa-ItemLink" target="_blank" href="${item._source.url}">${item._source.name}</a></div>
                      <a class="aa-ItemLink aa-ItemLink-text" target="_blank" href="${item._source.url}">${description}</a>
                      <div class="live-search-product__link">
                        <a target="_blank" href="${item._source.url}">${item._source.url}<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                      </div>
                      <div class="live-search-item__dividor"></div>
                    </div>
                  </div>`;
              }
            }
          }
        }
      ])
    },
    navigator: {
      navigate({ itemUrl }) {
        window.location.assign(itemUrl);
      },
      navigateNewTab({ itemUrl }) {
        const windowReference = window.open(itemUrl, '_blank', 'noopener');

        if (windowReference) {
          windowReference.focus();
        }
      },
      navigateNewWindow({ itemUrl }) {
        window.open(itemUrl, '_blank', 'noopener');
      },
    },
    onSubmit({state}){
      console.log('SUBMIT')
      window.location.href = `${searchUriBase}?search=${state.query}`;
    }
  });
}
